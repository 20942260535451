import React from 'react'
import Photo from './Photo'

export default function Gallery({ images, handleApproveToggle }) {
  return (
    <div className="gallery">
      {images.map(photo => (
        <Photo
          key={photo.url}
          photo={photo}
          handleApproveToggle={handleApproveToggle}
        />
      ))}
    </div>
  )
}

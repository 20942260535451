import React from 'react'

function formatNumber(nr) {
  if (typeof nr === 'undefined') {
    return 'Loading...'
  }
  if (String(nr) === 'null') {
    return '?'
  }
  return (Math.round(nr * 100) / 100)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export default function InfoTable(props) {
  return (
    <div className="info__content">
      <table>
        <tbody>
          <tr>
            <td>Totaal aantal planken</td>
            <td className="number">{formatNumber(props.info[0])}</td>
          </tr>
          <tr>
            <td>Totaal aantal foto's</td>
            <td className="number">{formatNumber(props.info[1])}</td>
          </tr>
          <tr>
            <td>Aantal L1 afgekeurde planken</td>
            <td className="number">{formatNumber(props.info[2])}</td>
          </tr>
          <tr>
            <td>Aantal L2 gelabelde planken</td>
            <td className="number">{formatNumber(props.info[3])}</td>
          </tr>
          <tr>
            <td>Aantal L3 gelabelde planken</td>
            <td className="number">{formatNumber(props.info[4])}</td>
          </tr>
          <tr>
            <td>Aantal planken per dag</td>
            <td className="number">
              {formatNumber(props.info[0] / props.info[5])}
            </td>
          </tr>
          <tr>
            <td>Aantal L1 afgekeurde planken per dag</td>
            <td className="number">
              {formatNumber(props.info[2] / props.info[5])}
            </td>
          </tr>
          <tr>
            <td>Aantal L2 planken per dag</td>
            <td className="number">
              {formatNumber(props.info[3] / props.info[5])}
            </td>
          </tr>
          <tr>
            <td>Aantal L3 planken per dag</td>
            <td className="number">
              {formatNumber(props.info[4] / props.info[5])}
            </td>
          </tr>
        </tbody>
      </table>

      <table>
        <tbody>
          <tr>
            <th>Type impuriteit</th>
            <th># foto's</th>
            <th># unieke planken</th>
          </tr>
          <tr>
            <td>Oppervlakte</td>
            <td className="number">{formatNumber(props.info[12])}</td>
            <td className="number">{formatNumber(props.info[6])}</td>
          </tr>
          <tr>
            <td>Knoop</td>
            <td className="number">{formatNumber(props.info[13])}</td>
            <td className="number">{formatNumber(props.info[7])}</td>
          </tr>
          <tr>
            <td>Hars</td>
            <td className="number">{formatNumber(props.info[14])}</td>
            <td className="number">{formatNumber(props.info[8])}</td>
          </tr>
          <tr>
            <td>Barst</td>
            <td className="number">{formatNumber(props.info[15])}</td>
            <td className="number">{formatNumber(props.info[9])}</td>
          </tr>
          <tr>
            <td>Verkleuring</td>
            <td className="number">{formatNumber(props.info[16])}</td>
            <td className="number">{formatNumber(props.info[10])}</td>
          </tr>
          <tr>
            <td>Schade zijkant</td>
            <td className="number">{formatNumber(props.info[17])}</td>
            <td className="number">{formatNumber(props.info[11])}</td>
          </tr>
        </tbody>
      </table>

      <table>
        <tbody>
          <tr>
            <td>Aantal foto's met een of meerdere impuriteiten</td>
            <td className="number">{formatNumber(props.info[18])}</td>
          </tr>
          <tr>
            <td>Aantal foto's zonder impuriteiten</td>
            <td className="number">{formatNumber(props.info[19])}</td>
          </tr>
          <tr>
            <td>Aantal planken met een of meerdere impuriteiten</td>
            <td className="number">{formatNumber(props.info[20])}</td>
          </tr>
          <tr>
            <td>Aantal planken zonder impuriteiten</td>
            <td className="number">{formatNumber(props.info[21])}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

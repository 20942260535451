import React, { useEffect } from 'react'
import { useLocalStorage } from 'use-hooks'

import InfoTable from '../components/InfoTable'
import Nav from '../components/Nav'

export default function Info() {
  const [info, setInfo] = useLocalStorage('info', { results: [] })

  const fetchInfo = async () => {
    setInfo(await fetch('/api/info').then(res => res.json()))
  }

  useEffect(() => {
    fetchInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="info">
      <div className="subnav">
        <Nav />
      </div>
      <div className="infobar">
        <button onClick={() => fetchInfo(true)}>Refresh info now</button>
        {info.fetchedAt && (
          <small>Calculated at {new Date(info.fetchedAt).toTimeString()}</small>
        )}
      </div>
      <InfoTable info={info.results} />
    </div>
  )
}

import React, { useEffect } from 'react'
import '../App.css'
import { format } from 'date-fns'

import { getJSON, postJSON, putJSON } from '@bothrs/util/fetch'
import { unserialize } from '@bothrs/util/url'
import { toLocaleJSON } from '@bothrs/util/date'

import { problems } from '../components/Photo'
import Gallery from '../components/Gallery'
import Nav from '../components/Nav'
import { filter, plank } from '../state'

function Live({ onPlank }) {
  useEffect(() => {
    const io = require('socket.io-client')
    const socket = !window.location.host.indexOf('localhost')
      ? io('localhost:3360')
      : io()
    socket.on('plank', d => {
      console.log('dd', d)
      onPlank(d)
    })
    window.socky = socket

    console.log('listeneing')
  }, [onPlank])

  return <span></span>
}

export default class Train extends React.Component {
  constructor(props) {
    super(props)
    const query = unserialize(window.location.search)
    this.state = {
      name: '',
      greeting: '',
      currentPlank: '',
      cursor: parseInt(query.cursor, 10) || 0,
      plank: { loading: true, images: [] },
      plank_id: query.plank_id || '',
      dates: [],
      after_time: query.after_time || '04:00',
      date: query.date || '',
      rejected_in_factory: query.rejected_in_factory || false,
      sort: query.sort || 'date',
      live: false,
    }
    this.handleFilterRejected = this.handleFilterRejected.bind(this)
    // this.handleIdLookup = this.handleIdLookup.bind(this)
    this.setPlankData = this.setPlankData.bind(this)
    this.formattedTime = this.formattedTime.bind(this)
    this.handleApproveToggle = this.handleApproveToggle.bind(this)
    this.toggleLive = this.toggleLive.bind(this)
    window.addEventListener('keyup', e => this.handleKeyPress(e))
    document.title = 'Plank labelling'
  }

  componentDidMount() {
    this.getDates()
    // this.getPlank({})

    plank.setFilter({ ...this.state })
    this.subPlank = plank.subscribe(plank => this.setState({ plank }))
    this.subFilter = filter.subscribe(filter => {
      return this.setState(filter)
    })
  }

  componentWillUnmount() {
    this.subPlank()
    this.subFilter()
  }

  handleKeyPress(e) {
    if (e.keyCode === 39) {
      this.getNextPlank()
    }
    if (e.keyCode === 37) {
      this.getPreviousPlank()
    }
  }

  handleApproveToggle(image) {
    console.log('this', this.state.plank.images.indexOf(image), image)
    const index = this.state.plank.images.indexOf(image)
    if (index < 0) {
      return console.warn('image not found', image)
    }
    const images = this.state.plank.images.slice()
    images[index] = {
      ...this.plank.image,
      plank_label: image.plank_label === -1 ? 1 : -1,
    }
    console.warn('set plank state')
    this.setState({
      plank: {
        ...this.plank,
        images,
      },
    })
    postJSON(`/api/image`, {
      ID: image.ID,
      plank_label: image.plank_label === -1 ? 1 : -1,
    })
  }

  // handleIdInput(event) {
  //   this.setState({ plank_id: event.target.value })
  //   this.handleIdLookup()
  // }

  handleFilterRejected() {
    plank.updateFilter(state => ({
      rejected_in_factory: state.rejected_in_factory ? 0 : 1,
    }))
  }

  toggleLive() {
    this.setState(({ live }) => ({ live: !live }))
  }

  // handleIdLookup(event) {
  //   event && event.preventDefault && event.preventDefault()
  //   // TODO: api should return for which plank_id it is for
  //   const { plank_id, plank } = this.state
  //   getJSON(
  //     `/api/images?${serialize({
  //       plank_id: plank_id,
  //       filterLabeled: this.state.rejected_in_factory,
  //     })}`
  //   )
  //     .then(plank => this.setState({ plank, currentPlank: plank.id }))
  //     .catch(err => this.setState({ err }))
  // }

  // number => difference in days from now
  // string => date in iso6801
  handleFilterDate(date) {
    if (typeof date === 'undefined') {
      date = ''
    }
    if (typeof date === 'number') {
      date = new Date(Date.now() + 24 * 36e5 * date).toJSON().slice(0, 10)
    }
    console.log('set date', date)
    plank.assignFilter({
      cursor: 0,
      date,
    })
  }

  afterTimeLocal() {
    const utcDate = new Date(
      this.state.date + 'T' + this.state.after_time + 'Z'
    )
    return toLocaleJSON(utcDate).slice(11, 16)
  }
  handleAfterTimeInput(after_time) {
    if (after_time && after_time.length === 5) {
      const localDate = new Date(this.state.date + 'T' + after_time)
      const utcTime = localDate.toJSON().slice(11, 16)
      plank.updateFilter(state => ({
        cursor: 0,
        after_time: utcTime,
      }))
    }
  }

  onPlank() {
    this.getPlank({
      plank_id: '',
      currentPlank: '',
      cursor: 0,
    })
  }

  getPlank(options) {
    console.error('set plank state')
  }

  async getDates() {
    const dates = await getJSON('/api/dates')
    this.setState({ dates })
    if (!this.state.date) {
      plank.assignFilter({
        date: last(dates).date,
      })
      console.log('assign date', last(dates).date)
    }
  }

  getPreviousPlank() {
    plank.updateFilter(state => ({
      cursor: Math.max(0, state.cursor - 1),
    }))
  }

  getNextPlank() {
    plank.updateFilter(state => ({ cursor: (state.cursor || 0) + 1 }))
  }

  acceptAll() {
    this.state.plank.images.forEach(img => {
      if (!hasProblem(img) && !img.review_label) {
        // Update server-side
        putJSON('/api/labels', {
          plank_id: img.plank_id,
          image_index: img.image_index,
          review_label: 'accepted',
        }).then(() => {
          plank.update(a => a)
        })
      }
    })
    this.getNextPlank()
  }

  setPlankData(plank) {
    console.warn('set plank data')
    alert('setPlankData')
  }

  // DD/MM/YYYY HH:MM
  // date format yyyy-mm-dd
  // time format hh:MM:mm
  formattedTime() {
    const first = this.state.plank.images[0]
    if (!first) {
      return ''
    }
    const [date, time] = toLocaleJSON(
      new Date(first.date + 'T' + first.time + 'Z')
    ).split('T')
    let timestamp = ''
    if (date) {
      const year = date.split('-')[0]
      const month = date.split('-')[1]
      const days = date.split('-')[2]
      timestamp = days + '/' + month + '/' + year + ' '
    }
    if (time) {
      timestamp += time.slice(0, 8)
    }
    return timestamp
  }

  render() {
    return (
      <div className="App">
        <div className="subnav subnav--block">
          <Nav />
          <label className="subnav__item checkbox" htmlFor="rejectedcheck">
            <input
              type="checkbox"
              name="rejectedcheck"
              id="rejectedcheck"
              checked={this.state.rejected_in_factory}
              onChange={this.handleFilterRejected}
            />
            Fysiek afgekeurd
          </label>
          <label className="subnav__item checkbox" htmlFor="livecheck">
            <input
              type="checkbox"
              name="livecheck"
              id="livecheck"
              onChange={this.toggleLive}
            />
            Live
          </label>
          <label className="subnav__item">
            <select
              className="form-control form-control-sm"
              onChange={evt => this.handleFilterDate(evt.target.value)}
              value={this.state.date}
            >
              <option value="">All dates</option>
              {this.state.dates.map(date => (
                <option key={date.date}>{date.date}</option>
              ))}
            </select>
          </label>

          {this.state.date && (
            <label className="subnav__item">
              <input
                type="time"
                className="form-control form-control-sm"
                value={this.afterTimeLocal()}
                onChange={evt => this.handleAfterTimeInput(evt.target.value)}
              />
            </label>
          )}
          {this.state.live && <Live onPlank={() => this.onPlank()} />}
          <div className="subnav__spacer"></div>
          <div className="title">
            {this.state.plank.loading
              ? '...'
              : this.state.plank.plank_name || ''}
            <small>
              {this.state.plank.timestamp &&
                format(this.state.plank.timestamp * 1000, 'cccc HH:mm:ss')}
            </small>
          </div>
          <div className="subnav__spacer"></div>
          <div className="subnav__item prev-next">
            <button onClick={() => this.getPreviousPlank()}>Vorige</button>
            <button onClick={() => this.getNextPlank()}>Volgende</button>
            <button onClick={() => this.acceptAll()} className="btn--accept">
              Goedkeuren
            </button>
          </div>
        </div>
        <PlankData plank={this.state.plank}></PlankData>
        <Gallery
          images={this.state.plank.images}
          handleApproveToggle={this.handleApproveToggle}
        />
      </div>
    )
  }
}
function last(arr) {
  return arr[arr.length - 1] || {}
}

function PlankData({ plank }) {
  if (plank.loading) {
    return <div></div>
  }

  const l2 = plank.images.filter(
    i =>
      (i.review_label && i.review_label !== 'accepted') ||
      getLabels(i).length
  ).length
  const labeled = plank.images.filter(i => getLabels(i).length).length
  return (
    <div className="plank__texts">
      <div
        className={
          'plank__text' +
          (plank.factory_label === 'rejected' ? ' rejected' : '')
        }
      >
        L1: {plank.factory_label === 'rejected' ? 'Afgekeurd' : 'OK'}
      </div>
      <div className={'plank__text' + (l2 ? ' rejected' : '')}>
        L2: {l2} slices afgekeurd
      </div>
      <div className={'plank__text' + (labeled ? ' rejected' : '')}>
        L3: {labeled} slices gelabeld
      </div>
    </div>
  )
}

function getLabels({ labels }) {
  return typeof labels === 'string' ? JSON.parse(labels) : labels || []
}
function hasProblem(image) {
  return getLabels(image).find(a => problems.includes(a.label))
}

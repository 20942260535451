import React from 'react'

import { putJSON } from '@bothrs/util/fetch'

import { plank } from '../state'

export const problems = [
  'oppervlakte',
  'barst',
  'schade zijkant',
  'hars',
  'knoop',
  'verkleuring',
  'band',
]

const cdn = 'https://azmamdtwbo.cloudimg.io/v7/'
const bucket = 'https://storage.googleapis.com/gardenas/Dataset'
export default function Photo({ photo, showMeta, toggleLabel2 }) {
  const labels = getLabels(photo)
  // factory_label
  return (
    <div
      onClick={() => toggleRejected(photo)}
      className={
        'photo photo--review' +
        (showMeta ? ' photo--meta' : '') +
        (hasProblem(labels) ? ' photo--problem' : ' photo--' + photo.review_label)
      }
    >
      <img
        src={cdn + bucket + imageURL(photo) + '?width=400'}
        alt=""
        loading="lazy"
        width="200"
        height="150"
      />
      {showMeta ? (
        <div className="meta">
          L1:{' '}
          {photo.factory_label === 'accepted' ? (
            photo.factory_label
          ) : (
            <span className="badge badge-primary">{photo.factory_label}</span>
          )}
          <br />
          L2:{' '}
          {photo.review_label === 'accepted' ? (
            photo.review_label
          ) : (
            <span className="badge badge-warning">{photo.review_label}</span>
          )}
          <br />
          L3: {labels.map(Badge)}
        </div>
      ) : (
        <></>
      )}

      {labels.filter(la => la.width).map(Box)}

      <Tagger photo={photo} toggleLabel2={toggleLabel2} />
    </div>
  )
}
function imageURL(photo) {
  const { timestamp, plank_name, factory_label, image_index, url } = photo
  if (url) {
    return url
  }
  const date = new Date(timestamp * 1000).toJSON().slice(0, 10)
  const time = new Date(timestamp * 1000).toJSON().slice(11, 19)
  return `/${date}_${time}_plank_${plank_name}_${image_index}_${labelNum(
    factory_label
  )}.jpg`
}
function labelNum(label) {
  return label === 'accepted' ? 1 : 0
}

function Box({ x, y, label, width, height }, i) {
  return (
    <div
      key={i}
      className="box"
      label={label}
      style={{
        position: 'absolute',
        top: (y * 150) / 1080,
        left: (x * 200) / 1440,
        height: (height * 150) / 1080,
        width: (width * 200) / 1440,
      }}
    ></div>
  )
}

function Badge({ label }, i) {
  return (
    <span key={i} className="badge badge-danger">
      {label}
    </span>
  )
}
function Tagger({ photo, toggleLabel2 }) {
  const labels = getLabels(photo).map(a => a.label)
  return (
    <div className="tagger" onClick={stop}>
      {problems.map((problem, i) => (
        <label
          key={i}
          className={'check' + (labels.includes(problem) ? ' checked' : '')}
        >
          <input
            type="checkbox"
            checked={labels.includes(problem)}
            onChange={() => toggleLabel(photo, problem, toggleLabel2)}
          />
          {problem}
        </label>
      ))}
    </div>
  )
}

function stop(evt) {
  evt.stopPropagation()
}

// APIs
function toggleLabel(photo, label, toggleLabel2) {
  console.log('togglelabel', photo, label)
  const beforeLabels = getLabels(photo)
  const exists = beforeLabels.find(a => a.label === label)
  if (
    exists &&
    exists.width &&
    !window.confirm('Bestaande coordinaten verwijderen?')
  ) {
    return
  }
  const labels = exists
    ? beforeLabels.filter(a => a.label !== label)
    : beforeLabels.concat({
        labeled_at: Date.now(),
        label,
      })

  if (toggleLabel2) {
    toggleLabel2(labels)
  } else {
    // Update client-side
    plank.update(plank => ({
      ...plank,
      images: plank.images.map(img =>
        img.image_index === photo.image_index ? { ...img, labels } : img
      ),
    }))
  }

  // Update server-side
  putJSON('/api/labels', {
    plank_id: photo.plank_id,
    image_index: photo.image_index,
    labels,
  })
}

function toggleRejected(photo, toggleReview) {
  let { plank_id, image_index, review_label } = photo
  review_label = review_label === 'rejected' ? 'accepted' : 'rejected'
  console.log(
    {
      plank_id,
      image_index,
      review_label,
    },
    photo
  )

  if (toggleReview) {
    toggleReview(review_label)
  } else {
    // Update client-side
    plank.update(plank => ({
      ...plank,
      images: plank.images.map(img =>
        img.image_index === photo.image_index ? { ...img, review_label } : img
      ),
    }))
  }

  putJSON('/api/labels', {
    plank_id,
    image_index,
    review_label,
  })
}

function getLabels({ labels }) {
  return typeof labels === 'string' ? JSON.parse(labels) : labels || []
}
function hasProblem(labels) {
  return labels.find(a => problems.includes(a.label))
}

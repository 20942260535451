import { writable } from 'svelte/store'

import { getJSON } from '@bothrs/util/fetch'
import { memo } from '@bothrs/util/memo'
import { serialize } from '@bothrs/util/url'
import { unserialize } from '@bothrs/util/url'

const memoPlank = memo(getPlank, 60000)
const defaultPlank = { loading: true, plank_name: 'loading', images: [] }

const filterKeys = [
  'after_time',
  'cursor',
  'date',
  'rejected_in_factory',
  'sort',
]
const query = unserialize(window.location.search)
export const filter = writable({
  after_time: query.after_time || '04:00',
  cursor: parseInt(query.cursor || '0', 10) || 0,
  date: query.date || '',
  rejected_in_factory: query.rejected_in_factory || false,
  sort: query.sort || 'date',
})

export const plank = fetchable(defaultPlank, memoPlank)

async function getPlank(query, b, c) {
  if (!query.date) {
    return defaultPlank
  }
  return getJSON('/api/images?' + serialize(query))
    .then(d => {
      const title = d.plank_name ? 'Plank ' + d.plank_name : 'Geen plank'
      window.history.pushState(query, title, '?' + serialize(query))
      document.title = title
      // Make sure that images is set
      return { images: [], ...d }
    })
    .catch(err => ({ err }))
}

function fetchable(value, fetcher) {
  let options = {}
  const { set, update, subscribe } = writable(value, () => {
    refresh()
  })

  return {
    assignFilter,
    setFilter,
    updateFilter,
    refresh,
    update: (updater) => {
      memoPlank.reset(options)
      return update(updater)
    },
    subscribe,
  }

  function assignFilter(input) {
    refresh({ ...options, ...input })
  }

  function setFilter(input) {
    refresh(input)
  }

  function updateFilter(reducer) {
    refresh({ ...options, ...reducer(options) })
  }

  function refresh(input) {
    if (input) {
      options = pick(input, filterKeys)
    }
    filter.set(options)
    // update(item => ({ ...item, loading: true }))
    fetcher(options).then(set)

    // Prefetch
    setTimeout(() => {
      fetcher({ ...options, cursor: options.cursor + 1 })
    }, 200)
    setTimeout(() => {
      fetcher({ ...options, cursor: options.cursor + 1 })
    }, 400)
  }
}

// import Observable from 'zen-observable';

// Object representing a plank that is being inspected
// contains the labels of the related images
// export const plank = new Observable(observer => {
//   // Emit a single value after 1 second
//   let timer = setTimeout(() => {
//     observer.next('hello');
//     observer.complete();
//   }, 1000);

//   // On unsubscription, cancel the timer
//   return () => clearTimeout(timer);
// });

// export function useEventListener(eventName, handler, element = window){
//   const savedHandler = useRef();

//   useEffect(() => {
//     savedHandler.current = handler;
//   }, [handler]);

//   useEffect(
//     () => {
//       // Make sure element supports addEventListener
//       // On
//       const isSupported = element && element.addEventListener;
//       if (!isSupported) return;

//       // Create event listener that calls handler function stored in ref
//       const eventListener = event => savedHandler.current(event);

//       // Add event listener
//       element.addEventListener(eventName, eventListener);

//       // Remove event listener on cleanup
//       return () => {
//         element.removeEventListener(eventName, eventListener);
//       };
//     },
//     [eventName, element] // Re-run if eventName or element changes
//   );
// };

function pick(obj, keys) {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, val]) => keys.includes(key))
  )
}

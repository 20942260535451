import React from 'react'

import { NavLink } from 'react-router-dom'

export default function Nav() {
  return (
    <nav>
      <NavLink to="/train">Train</NavLink>
      <NavLink to="/info">Info</NavLink>
    </nav>
  )
}

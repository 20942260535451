import React, { useState, useEffect } from 'react'
// eslint-disable-next-line
import { useLocalStorage } from 'use-hooks'
import { FixedSizeList } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'

import { serialize } from '@bothrs/util/url'
import _debounce from 'lodash/debounce'

import Nav from '../components/Nav'
import Photo from '../components/Photo'

let loading = {}

const syncHash = _debounce(a => {
  // window.location.hash = serialize({
  //   top: document.querySelector('.content>div').scrollTop,
  // })
})

function scrollToBottom(argument) {
  document.querySelector('.review__scrollable').scrollTop = 10000 * 200
}

export default function Review() {
  return ReviewNearbyPhotos()
}
export function ReviewNearbyPhotos() {
  useEffect(() => {
    loading = {}
  }, [])
  const [itemCount] = useState(1800)
  const [items, setItems] = useState({})
  // const [items, setItems] = useLocalStorage('reviews', {})

  function loadMoreItems(a, b) {
    const toLoad = []
    for (var i = a; i <= b; i++) {
      if (!loading[i]) {
        toLoad.push(i)
      }
    }
    if (toLoad.length) fetchLabels(toLoad.slice(0, 100))

    syncHash(a)
  }

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = index => items[index]

  // Render an item or a loading indicator.
  const Item = ({ index, style }) => {
    if (!isItemLoaded(index)) {
      return <div style={style}>Loading...</div>
    }
    const prev2 =
      items[index - 1] &&
      items[index].plank_name === items[index - 1].plank_name &&
      items[index].image_index === items[index - 1].image_index + 2
    const prev1 =
      items[index - 1] &&
      items[index].plank_name === items[index - 1].plank_name &&
      items[index].image_index === items[index - 1].image_index + 1
    const p = items[index]
    return (
      <div className="review__row" style={style} id={'cursor-' + index}>
        {!prev1 && !prev2 ? (
          <LazyPhoto key={1} image_index={p.image_index - 2} photo={p} />
        ) : (
          <NoPhoto />
        )}
        {!prev1 ? (
          <LazyPhoto key={2} image_index={p.image_index - 1} photo={p} />
        ) : (
          <NoPhoto />
        )}
        <Photo
          key={3}
          photo={p}
          toggleLabel2={labels => toggleLabel(index, labels)}
          showMeta
        />
      </div>
    )

    function toggleLabel(index, labels) {
      // setItems
      setItems(items => ({
              ...items,
              [index]: {
                ...items[index],
                labels
              }
            }))
    }
  }

  // <LazyPhoto key={4} image_index={p.image_index + 1} photo={p} />
  //       <LazyPhoto key={5} image_index={p.image_index + 2} photo={p} />

  const fetchLabels = async range => {
    const cursor = range[0]
    const limit = Math.min(range[range.length - 1] - range[0] + 1, 100)
    if (!limit) {
      return
    }
    range.forEach(v => (loading[v] = 1))
    console.log('laodnex', range)
    const labels = await fetch(
      '/api/labels?' +
        serialize({
          cursor,
          limit,
        })
    ).then(res => res.json())
    setItems(items => {
      console.log('fetched labels', labels)
      items = Object.assign({}, items)
      labels.forEach((label, i) => (items[cursor + i] = label))
      return items
    })
  }

  useEffect(() => {
    fetchLabels([0, 1, 2])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="review">
      <div className="subnav">
        <Nav />
        <div className="subnav__item">
          <input type="text" placeholder="offset" style={{ width: 60 }} />
        </div>
        <button type="button" onClick={scrollToBottom}>
          Scroll to bottom
        </button>
      </div>
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        itemCount={itemCount}
        loadMoreItems={loadMoreItems}
      >
        {({ onItemsRendered, ref }) => (
          <FixedSizeList
            className="review__scrollable"
            itemCount={itemCount}
            onItemsRendered={onItemsRendered}
            ref={ref}
            height={window.innerHeight - 40}
            itemSize={166}
            width="100%"
          >
            {Item}
          </FixedSizeList>
        )}
      </InfiniteLoader>
    </div>
  )
}

function LazyPhoto({ photo, image_index }) {
  if (image_index < 0 || photo.image_count <= image_index) {
    return <NoPhoto />
  }
  return <Photo photo={{ ...photo, image_index, labels: null }} />
}

function NoPhoto({ photo, image_index }) {
  return <div className="photo photo--lazy photo--review"></div>
}

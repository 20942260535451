import React from 'react'
import './App.css'

// Router
import { Route, BrowserRouter } from 'react-router-dom'
// Pages
import Info from './pages/Info'
import Review from './pages/Review'
import Train from './pages/Train'
import Nav from './components/Nav'

export default function App() {
  return (
    <BrowserRouter>
      <Route exact path="/" component={Home} />
      <Route path="/train" component={Train} />
      <Route path="/review" component={Review} />
      <Route path="/info" component={Info} />
    </BrowserRouter>
  )
}

function Home() {
  window.location.pathname = '/train'
  return (
    <div className="subnav">
      <Nav />
    </div>
  )
}

// class App extends React.Component {
//   constructor(props) {
//     super(props)
//     const query = unserialize(window.location.search)
//     this.state = {
//       name: '',
//       greeting: '',
//       currentPlank: '',
//       cursor: parseInt(query.cursor, 10) || 0,
//       plank: { loading: true, images: [] },
//       plank_id: query.plank_id || '',
//       dates: [],
//       filter: {
//         after_time: query.after_time || '04:00',
//         date: query.date || '',
//         rejected_in_factory: query.rejected_in_factory || false,
//       },
//       sort: query.sort || 'date',
//       info: ls('/api/info') || { results: [] },
//       showInfo: query.info || false,
//     }
//     this.handleFilterRejected = this.handleFilterRejected.bind(this)
//     // this.handleIdLookup = this.handleIdLookup.bind(this)
//     this.setPlankData = this.setPlankData.bind(this)
//     this.formattedTime = this.formattedTime.bind(this)
//     this.handleApproveToggle = this.handleApproveToggle.bind(this)
//     this.toggleInfo = this.toggleInfo.bind(this)
//     this.fetchInfo = this.fetchInfo.bind(this)
//     window.addEventListener('keyup', e => this.handleKeyPress(e))
//     document.title = 'Plank labelling'
//   }

//   componentDidMount() {
//     this.getDates()
//     this.getPlank({})
//   }

//   handleKeyPress(e) {
//     console.log('e', e)
//     console.log(this.state)
//     if (e.keyCode === 39) {
//       this.getNextPlank()
//     }
//     if (e.keyCode === 37) {
//       this.getPreviousPlank()
//     }
//   }

//   handleApproveToggle(image) {
//     console.log('this', this.state.plank.images.indexOf(image), image)
//     const index = this.state.plank.images.indexOf(image)
//     if (index < 0) {
//       return console.warn('image not found', image)
//     }
//     const images = this.state.plank.images.slice()
//     images[index] = {
//       ...this.plank.image,
//       plank_label: image.plank_label === -1 ? 1 : -1,
//     }
//     this.setState({
//       plank: {
//         ...this.plank,
//         images,
//       },
//     })
//     postJSON(`/api/image`, {
//       ID: image.ID,
//       plank_label: image.plank_label === -1 ? 1 : -1,
//     })
//   }

//   // handleIdInput(event) {
//   //   this.setState({ plank_id: event.target.value })
//   //   this.handleIdLookup()
//   // }

//   handleFilterRejected() {
//     this.getPlank({
//       filter: {
//         ...this.state.filter,
//         rejected_in_factory: this.state.filter.rejected_in_factory ? 0 : 1,
//       },
//     })
//   }

//   // handleIdLookup(event) {
//   //   event && event.preventDefault && event.preventDefault()
//   //   // TODO: api should return for which plank_id it is for
//   //   const { plank_id, plank } = this.state
//   //   getJSON(
//   //     `/api/images?${serialize({
//   //       plank_id: plank_id,
//   //       filterLabeled: this.state.filter.rejected_in_factory,
//   //     })}`
//   //   )
//   //     .then(plank => this.setState({ plank, currentPlank: plank.id }))
//   //     .catch(err => this.setState({ err }))
//   // }

//   // number => difference in days from now
//   // string => date in iso6801
//   handleFilterDate(date) {
//     if (typeof date === 'undefined') {
//       date = ''
//     }
//     if (typeof date === 'number') {
//       date = new Date(Date.now() + 24 * 36e5 * date).toJSON().slice(0, 10)
//     }
//     console.log('set date', date)
//     this.getPlank({
//       plank_id: '',
//       currentPlank: '',
//       cursor: 0,
//       filter: { ...this.state.filter, date },
//     })
//   }

//   afterTimeLocal() {
//     const utcDate = new Date(
//       this.state.filter.date + 'T' + this.state.filter.after_time + 'Z'
//     )
//     return toLocaleJSON(utcDate).slice(11, 16)
//   }
//   handleAfterTimeInput(after_time) {
//     if (after_time && after_time.length === 5) {
//       const localDate = new Date(this.state.filter.date + 'T' + after_time)
//       const utcTime = localDate.toJSON().slice(11, 16)
//       this.getPlank({
//         cursor: 0,
//         filter: { ...this.state.filter, after_time: utcTime },
//       })
//     }
//   }

//   getPlank(options) {
//     this.setState(options)
//     options = { ...this.state, ...options }
//     const query = {
//       ...options.filter,
//       sort: options.sort,
//       cursor: options.cursor,
//       plank_id: options.plank_id,
//     }
//     window.history.pushState(query, 'plank', '?' + serialize(query))
//     return getJSON('/api/images?' + serialize(query))
//       .then(this.setPlankData)
//       .catch(err => this.setState({ err }))
//   }

//   getDates() {
//     return getJSON('/api/dates').then(dates => {
//       this.setState({ dates })
//       if (!this.state.filter.date) {
//         this.getPlank({
//           filter: {
//             ...this.state.filter,
//             date: last(dates).date,
//           },
//         })
//       }
//     })
//   }

//   getPreviousPlank() {
//     return this.getPlank({ cursor: Math.max(0, this.state.cursor - 1) })
//   }

//   getNextPlank() {
//     return this.getPlank({ cursor: this.state.cursor + 1 })
//   }

//   setPlankData(plank) {
//     if (!plank.images || !plank.images[0]) {
//       plank.images = []
//     }
//     this.setState({
//       plank,
//       currentPlank: plank.id,
//     })
//   }

//   toggleInfo() {
//     if (!this.state.showInfo) {
//       this.fetchInfo()
//     }
//     this.setState({ showInfo: !this.state.showInfo })
//   }

//   fetchInfo(reset) {
//     if (reset) {
//       this.setState({ info: { results: [] } })
//     }
//     fetch('/api/info')
//       .then(res => res.json())
//       .then(info => {
//         this.setState({ info })
//         ls('/api/info', info)
//       })
//   }

//   // DD/MM/YYYY HH:MM
//   // date format yyyy-mm-dd
//   // time format hh:MM:mm
//   formattedTime() {
//     const first = this.state.plank.images[0]
//     if (!first) {
//       return ''
//     }
//     const [date, time] = toLocaleJSON(
//       new Date(first.date + 'T' + first.time + 'Z')
//     ).split('T')
//     let timestamp = ''
//     if (date) {
//       const year = date.split('-')[0]
//       const month = date.split('-')[1]
//       const days = date.split('-')[2]
//       timestamp = days + '/' + month + '/' + year + ' '
//     }
//     if (time) {
//       timestamp += time.slice(0, 8)
//     }
//     return timestamp
//   }

//   countRejectedSlices() {
//     return this.state.plank.images.filter(
//       i => i.plank_label && i.plank_label < 1
//     ).length
//   }

//   countAcceptedSlices() {
//     return this.state.plank.images.filter(
//       i => i.plank_label && i.plank_label > 1
//     ).length
//   }

//   render() {
//     console.log('location', window.location.href)
//     if (this.state.showInfo) {
//       // get data
//       return <Info />
//     } else {
//       const { factory_label } = this.state.plank
//       return (
//         <div className="App">
//           <div className="header">
//             <div className="filters">
//               <h4>Filters</h4>
//               <label className="checkbox" htmlFor="check">
//                 <input
//                   type="checkbox"
//                   name="check"
//                   id="check"
//                   checked={this.state.filter.rejected_in_factory}
//                   onChange={this.handleFilterRejected}
//                 />
//                 Alleen fysiek afgekeurd
//               </label>
//               <select
//                 onChange={evt => this.handleFilterDate(evt.target.value)}
//                 value={this.state.filter.date}
//               >
//                 <option value="">All dates</option>
//                 {this.state.dates.map(date => (
//                   <option key={date.date}>{date.date}</option>
//                 ))}
//               </select>
//               {this.state.filter.date && (
//                 <input
//                   type="time"
//                   className="inp"
//                   value={this.afterTimeLocal()}
//                   onChange={evt => this.handleAfterTimeInput(evt.target.value)}
//                 />
//               )}
//             </div>
//             <div className="prev-next">
//               <button onClick={() => this.getPreviousPlank()}>Vorige</button>
//               <div className="title">
//                 {this.state.plank.loading
//                   ? '...'
//                   : this.state.plank.plank_name || ''}
//               </div>
//               <button onClick={() => this.getNextPlank()}>Volgende</button>
//             </div>
//             <div className="centeredTimestamp">
//               {this.state.plank.timestamp &&
//                 format(this.state.plank.timestamp * 1000, 'ccc d MMM HH:mm:ss')}

//               {factory_label === 'rejected' ? (
//                 <div className="status status--factory">Fysiek afgekeurd</div>
//               ) : (
//                 ''
//               )}
//               {this.countRejectedSlices() ? (
//                 <div className="status status--slices">
//                   {this.countRejectedSlices()} slices afgekeurd
//                 </div>
//               ) : (
//                 ''
//               )}
//             </div>
//             <button className="toggleInfo" onClick={() => this.toggleInfo()}>
//               Info
//             </button>
//           </div>
//           <Gallery
//             images={this.state.plank.images}
//             handleApproveToggle={this.handleApproveToggle}
//           />
//         </div>
//       )
//     }
//   }
// }

// export default App

// // <form>
// //           <label htmlFor="name"></label>
// //           <input
// //             id="name"
// //             type="text"
// //             placeholder="plank_id"
// //             value={this.state.name}
// //             onInput={this.handleIdInput}
// //           />
// //           <button type="submit">Submit</button>
// // </form>

// function last(arr) {
//   return arr[arr.length - 1] || {}
// }
